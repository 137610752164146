<template>
    <AppLayout>
        <template v-slot:appContent>
            <div>
                <div class="notification-wrapper">
                  <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
                    <h4 class="">Notification/List</h4>
                  </div>
                    <div class="accordion" id="accordionNotification">
                        <div class="card">
                            <div class="card-header" id="notificationForgotPass">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseForgotPass" aria-expanded="true" aria-controls="collapseForgotPass">
                                    <span class="text-capitalize notification-title">Forget Password</span>
                                </button>
                            </div>
                            <div id="collapseForgotPass" class="collapse" aria-labelledby="notificationForgotPass" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <ForgetPasswordNotification 
                                    :notification="notifications.notification_forgot_password_notify" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCustomerCredentials">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCustomerCredentials" aria-expanded="true" aria-controls="collapseCustomerCredentials">
                                    <span class="text-capitalize notification-title">Customer Credentials</span>
                                </button>
                            </div>
                            <div id="collapseCustomerCredentials" class="collapse" aria-labelledby="notificatioCustomerCredentialso" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CustomerCredentialNotification 
                                    :notification="notifications.notification_customer_credentials" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler"
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationInternalUserCredentials">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseInternalUserCredentials" aria-expanded="true" aria-controls="collapseInternalUserCredentials">
                                    <span class="text-capitalize notification-title">Internal User Credentials</span>
                                </button>
                            </div>
                            <div id="collapseInternalUserCredentials" class="collapse" aria-labelledby="notificationInternalUserCredentials" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <InternalUserCredentialNotification 
                                    :notification="notifications.notification_internal_user_credentials" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler"
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"  
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationContactRequestAcknowledgementRequester">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseContactRequestAcknowledgementRequester" aria-expanded="true" aria-controls="collapseContactRequestAcknowledgementRequester">
                                    <span class="text-capitalize notification-title">Contact Request Acknowledgement to Requester</span>
                                </button>
                            </div>
                            <div id="collapseContactRequestAcknowledgementRequester" class="collapse" aria-labelledby="notificationContactRequestAcknowledgementRequester" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <ContactRequestAcknowledgementRequesterNotification 
                                    :notification="notifications.notification_contact_request_acknowledgement_requester" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler"
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"  
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationContactRequestAcknowledgementInternal">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseContactRequestAcknowledgementInternal" aria-expanded="true" aria-controls="collapseContactRequestAcknowledgementInternal">
                                    <span class="text-capitalize notification-title">Contact Request Acknowledgement to Internal User</span>
                                </button>
                            </div>
                            <div id="collapseContactRequestAcknowledgementInternal" class="collapse" aria-labelledby="notificationContactRequestAcknowledgementInternal" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <ContactRequestAcknowledgementInternalUserNotification 
                                    :notification="notifications.notification_contact_request_acknowledgement_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler"
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentCreationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentCreationCustomer" aria-expanded="true" aria-controls="collapseAppointmentCreationCustomer">
                                    <span class="text-capitalize notification-title">Appointment Creation Customer</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentCreationCustomer" class="collapse" aria-labelledby="notificationAppointmentCreationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentCreateCustomerNotification 
                                    :notification="notifications.notification_appointment_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentCreationInternal">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentCreationInternal" aria-expanded="true" aria-controls="collapseAppointmentCreationInternal">
                                    <span class="text-capitalize notification-title">Appointment Creation Internal User</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentCreationInternal" class="collapse" aria-labelledby="notificationAppointmentCreationInternal" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentCreateInternalUserNotification 
                                    :notification="notifications.notification_appointment_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler"
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentCreationTechnician">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentCreationTechnician" aria-expanded="true" aria-controls="collapseAppointmentCreationTechnician">
                                    <span class="text-capitalize notification-title">Appointment Creation Technician</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentCreationTechnician" class="collapse" aria-labelledby="notificationAppointmentCreationTechnician" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentCreateTechNotification 
                                    :notification="notifications.notification_appointment_creation_technician" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentStatusUpdateCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentStatusUpdateCustomer" aria-expanded="true" aria-controls="collapseAppointmentStatusUpdateCustomer">
                                    <span class="text-capitalize notification-title">Appointment Status Update Customer</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentStatusUpdateCustomer" class="collapse" aria-labelledby="notificationAppointmentStatusUpdateCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentStatusUpdateCustomerNotification 
                                    :notification="notifications.notification_appointment_status_update_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentStatusUpdateInternal">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentStatusUpdateInternal" aria-expanded="true" aria-controls="collapseAppointmentStatusUpdateInternal">
                                    <span class="text-capitalize notification-title">Appointment Status Update Internal User</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentStatusUpdateInternal" class="collapse" aria-labelledby="notificationAppointmentStatusUpdateInternal" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentStatusUpdateInternalUserNotification 
                                    :notification="notifications.notification_appointment_status_update_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentStatusUpdateTechnician">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentStatusUpdateTechnician" aria-expanded="true" aria-controls="collapseAppointmentStatusUpdateTechnician">
                                    <span class="text-capitalize notification-title">Appointment Status Update Technician</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentStatusUpdateTechnician" class="collapse" aria-labelledby="notificationAppointmentStatusUpdateTechnician" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentStatusUpdateTechnicianNotification 
                                    :notification="notifications.notification_appointment_status_update_technician" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderCreationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderCreationCustomer" aria-expanded="true" aria-controls="collapseOrderCreationCustomer">
                                    <span class="text-capitalize notification-title">Order Creation Customer</span>
                                </button>
                            </div>
                            <div id="collapseOrderCreationCustomer" class="collapse" aria-labelledby="notificationOrderCreationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderCreationCustomerNotification 
                                    :notification="notifications.notification_order_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderCreationInternal">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderCreationInternal" aria-expanded="true" aria-controls="collapseOrderCreationInternal">
                                    <span class="text-capitalize notification-title">Order Creation Internal User</span>
                                </button>
                            </div>
                            <div id="collapseOrderCreationInternal" class="collapse" aria-labelledby="notificationOrderCreationInternal" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderCreationInternalUserNotification 
                                    :notification="notifications.notification_order_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderStatusUpdateCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderStatusUpdateCustomer" aria-expanded="true" aria-controls="collapseOrderStatusUpdateCustomer">
                                    <span class="text-capitalize notification-title">Order Status Update Customer</span>
                                    <!-- <span class="text-capitalize notification-title">notification_order_creation_internal_user</span> -->
                                </button>
                            </div>
                            <div id="collapseOrderStatusUpdateCustomer" class="collapse" aria-labelledby="notificationOrderStatusUpdateCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderStatusUpdateCustomerNotification 
                                    :notification="notifications.notification_order_status_update_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderStatusUpdateInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderStatusUpdateInternalUser" aria-expanded="true" aria-controls="collapseOrderStatusUpdateInternalUser">
                                    <span class="text-capitalize notification-title">Order Status Update Internal User</span>
                                </button>
                            </div>
                            <div id="collapseOrderStatusUpdateInternalUser" class="collapse" aria-labelledby="notificationOrderStatusUpdateInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderStatusUpdateInternalUserNotification 
                                    :notification="notifications.notification_order_status_update_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentCreditedCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentCreditedCustomer" aria-expanded="true" aria-controls="collapseAppointmentCreditedCustomer">
                                    <span class="text-capitalize notification-title">Appointment Credited Customer</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentCreditedCustomer" class="collapse" aria-labelledby="notificationAppointmentCreditedCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentCreditedCustomerNotification 
                                    :notification="notifications.notification_appointment_credited_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentCreditedInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentCreditedInternalUser" aria-expanded="true" aria-controls="collapseAppointmentCreditedInternalUser">
                                    <span class="text-capitalize notification-title">Appointment Credited Internal User</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentCreditedInternalUser" class="collapse" aria-labelledby="notificationAppointmentCreditedInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentCreditedInternalUserNotification 
                                    :notification="notifications.notification_appointment_credited_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationRefundInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseRefundInternalUser" aria-expanded="true" aria-controls="collapseRefundInternalUser">
                                    <span class="text-capitalize notification-title">Refund Internal User</span>
                                </button>
                            </div>
                            <div id="collapseRefundInternalUser" class="collapse" aria-labelledby="notificationRefundInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <RefundInternalUserNotification 
                                    :notification="notifications.notification_refund_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentCreationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentCreationCustomer" aria-expanded="true" aria-controls="collapseCashPaymentCreationCustomer">
                                    <span class="text-capitalize notification-title">Cash Payment Creation Customer</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentCreationCustomer" class="collapse" aria-labelledby="notificationCashPaymentCreationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentCreationCustomerNotification 
                                    :notification="notifications.notification_cash_payment_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentCreationInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentCreationInternalUser" aria-expanded="true" aria-controls="collapseCashPaymentCreationInternalUser">
                                    <span class="text-capitalize notification-title">Cash Payment Creation Internal User</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentCreationInternalUser" class="collapse" aria-labelledby="notificationCashPaymentCreationInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentCreationInternalUserNotification 
                                    :notification="notifications.notification_cash_payment_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCardPaymentCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCardPaymentCustomer" aria-expanded="true" aria-controls="collapseCardPaymentCustomer">
                                    <span class="text-capitalize notification-title">Card Payment Creation Customer</span>
                                </button>
                            </div>
                            <div id="collapseCardPaymentCustomer" class="collapse" aria-labelledby="notificationCardPaymentCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CardPaymentCreationCustomerNotification 
                                    :notification="notifications.notification_card_payment_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCardPaymentInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCardPaymentInternalUser" aria-expanded="true" aria-controls="collapseCardPaymentInternalUser">
                                    <span class="text-capitalize notification-title">Card Payment Creation Internal User</span>
                                </button>
                            </div>
                            <div id="collapseCardPaymentInternalUser" class="collapse" aria-labelledby="notificationCardPaymentInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CardPaymentCreationInternalUserNotification 
                                    :notification="notifications.notification_card_payment_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationEftPaymentCreationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseEftPaymentCreationCustomer" aria-expanded="true" aria-controls="collapseEftPaymentCreationCustomer">
                                    <span class="text-capitalize notification-title">EFT Payment Creation Customer</span>
                                </button>
                            </div>
                            <div id="collapseEftPaymentCreationCustomer" class="collapse" aria-labelledby="notificationEftPaymentCreationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <EftPaymentCreationCustomerNotification 
                                    :notification="notifications.notification_eft_payment_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationEftPaymentInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseEftPaymentInternalUser" aria-expanded="true" aria-controls="collapseEftPaymentInternalUser">
                                    <span class="text-capitalize notification-title">EFT Payment Creation Internal User</span>
                                </button>
                            </div>
                            <div id="collapseEftPaymentInternalUser" class="collapse" aria-labelledby="notificationEftPaymentInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <EftPaymentCreationInternalUserNotification 
                                    :notification="notifications.notification_eft_payment_creation_internal_user"
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCardPaymentStatusUpdateCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCardPaymentStatusUpdateCustomer" aria-expanded="true" aria-controls="collapseCardPaymentStatusUpdateCustomer">
                                    <span class="text-capitalize notification-title">Card Payment Status Update Customer</span>
                                </button>
                            </div>
                            <div id="collapseCardPaymentStatusUpdateCustomer" class="collapse" aria-labelledby="notificationCardPaymentStatusUpdateCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CardPaymentStatusUpdateCustomerNotification 
                                    :notification="notifications.notification_card_payment_status_update_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCardPaymentStatusUpdateInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCardPaymentStatusUpdateInternalUser" aria-expanded="true" aria-controls="collapseCardPaymentStatusUpdateInternalUser">
                                    <span class="text-capitalize notification-title">Card Payment Status Update Internal User</span>
                                </button>
                            </div>
                            <div id="collapseCardPaymentStatusUpdateInternalUser" class="collapse" aria-labelledby="notificationCardPaymentStatusUpdateInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CardPaymentStatusUpdateInternalUserNotification 
                                    :notification="notifications.notification_card_payment_status_update_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentStatusUpdateInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentStatusUpdateInternalUser" aria-expanded="true" aria-controls="collapseCashPaymentStatusUpdateInternalUser">
                                    <span class="text-capitalize notification-title">Cash Payment Pending Status Internal User</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentStatusUpdateInternalUser" class="collapse" aria-labelledby="notificationCashPaymentStatusUpdateInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentPendingStatusUpdateInternalUserNotification 
                                    :notification="notifications.notification_cash_payment_pending_status_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationShareCustomerOpinion">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseShareCustomerOpinion" aria-expanded="true" aria-controls="collapseShareCustomerOpinion">
                                    <span class="text-capitalize notification-title">Customer Opinion Share</span>
                                </button>
                            </div>
                            <div id="collapseShareCustomerOpinion" class="collapse" aria-labelledby="notificationShareCustomerOpinion" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <ShareCustomerOpinionNotification 
                                    :notification="notifications.notification_share_opinion_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationServiceAgreementCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseServiceAgreementCustomer" aria-expanded="true" aria-controls="collapseServiceAgreementCustomer">
                                    <span class="text-capitalize notification-title">Service Agreement Customer</span>
                                </button>
                            </div>
                            <div id="collapseServiceAgreementCustomer" class="collapse" aria-labelledby="notificationServiceAgreementCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <ServiceAgreementCustomerNotification 
                                    :notification="notifications.notification_service_agreement_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationServiceAgreementInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseServiceAgreementInternalUser" aria-expanded="true" aria-controls="collapseServiceAgreementInternalUser">
                                    <span class="text-capitalize notification-title">Service Agreement Internal User</span>
                                </button>
                            </div>
                            <div id="collapseServiceAgreementInternalUser" class="collapse" aria-labelledby="notificationServiceAgreementInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <ServiceAgreementInternalUserNotification 
                                    :notification="notifications.notification_service_agreement_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentDeliveredInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentDeliveredInternalUser" aria-expanded="true" aria-controls="collapseAppointmentDeliveredInternalUser">
                                    <span class="text-capitalize notification-title">Appointment Delivered Internal User</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentDeliveredInternalUser" class="collapse" aria-labelledby="notificationAppointmentDeliveredInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentDeliveredInternalUserNotification 
                                    :notification="notifications.notification_appointment_delivered_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentDeliveredCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentDeliveredCustomer" aria-expanded="true" aria-controls="collapseAppointmentDeliveredCustomer">
                                    <span class="text-capitalize notification-title">Appointment Delivered Customer</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentDeliveredCustomer" class="collapse" aria-labelledby="notificationAppointmentDeliveredCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentDeliveredCustomerNotification 
                                    :notification="notifications.notification_appointment_delivered_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentDeliveredTechnician">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentDeliveredTechnician" aria-expanded="true" aria-controls="collapseAppointmentDeliveredTechnician">
                                    <span class="text-capitalize notification-title">Appointment Delivered Technician</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentDeliveredTechnician" class="collapse" aria-labelledby="notificationAppointmentDeliveredTechnician" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentDeliveredTechnicianNotification 
                                    :notification="notifications.notification_appointment_delivered_technician" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentInvoiceRequestInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentInvoiceRequestInternalUser" aria-expanded="true" aria-controls="collapseAppointmentInvoiceRequestInternalUser">
                                    <span class="text-capitalize notification-title">Appointment invoice request internal user</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentInvoiceRequestInternalUser" class="collapse" aria-labelledby="notificationAppointmentInvoiceRequestInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentInvoiceRequestInternalUserNotification 
                                    :notification="notifications.notification_appointment_invoice_request_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentInvoiceRequestCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentInvoiceRequestCustomer" aria-expanded="true" aria-controls="collapseAppointmentInvoiceRequestCustomer">
                                    <span class="text-capitalize notification-title">Appointment invoice request customer</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentInvoiceRequestCustomer" class="collapse" aria-labelledby="notificationAppointmentInvoiceRequestCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentInvoiceRequestCustomerNotification 
                                    :notification="notifications.notification_appointment_invoice_request_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationSubscriptionRenewalInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseSubscriptionRenewalInternalUser" aria-expanded="true" aria-controls="collapseSubscriptionRenewalInternalUser">
                                    <span class="text-capitalize notification-title">Subscription Renewal Internal User</span>
                                </button>
                            </div>
                            <div id="collapseSubscriptionRenewalInternalUser" class="collapse" aria-labelledby="notificationSubscriptionRenewalInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <SubscriptionRenewalInternalUserNotification 
                                    :notification="notifications.notification_subscription_renewal_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationSubscriptionRenewalCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseSubscriptionRenewalCustomer" aria-expanded="true" aria-controls="collapseSubscriptionRenewalCustomer">
                                    <span class="text-capitalize notification-title">Subscription Renewal Customer</span>
                                </button>
                            </div>
                            <div id="collapseSubscriptionRenewalCustomer" class="collapse" aria-labelledby="notificationSubscriptionRenewalCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <SubscriptionRenewalCustomerNotification 
                                    :notification="notifications.notification_subscription_renewal_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationRefundStatusUpdateInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseRefundStatusUpdateInternalUser" aria-expanded="true" aria-controls="collapseRefundStatusUpdateInternalUser">
                                    <span class="text-capitalize notification-title">Refund status update internal user</span>
                                </button>
                            </div>
                            <div id="collapseRefundStatusUpdateInternalUser" class="collapse" aria-labelledby="notificationRefundStatusUpdateInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <RefundStatusUpdateInternalUserNotification 
                                    :notification="notifications.notification_refund_status_update_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCustomerRefundedInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCustomerRefundedInternalUser" aria-expanded="true" aria-controls="collapseCustomerRefundedInternalUser">
                                    <span class="text-capitalize notification-title">Customer refunded internal user</span>
                                </button>
                            </div>
                            <div id="collapseCustomerRefundedInternalUser" class="collapse" aria-labelledby="notificationCustomerRefundedInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CustomerRefundedInternalUserNotification 
                                    :notification="notifications.notification_customer_refunded_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCustomerRefundedCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCustomerRefundedCustomer" aria-expanded="true" aria-controls="collapseCustomerRefundedCustomer">
                                    <span class="text-capitalize notification-title">Customer refunded customer</span>
                                </button>
                            </div>
                            <div id="collapseCustomerRefundedCustomer" class="collapse" aria-labelledby="notificationCustomerRefundedCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CustomerRefundedCustomerNotification 
                                    :notification="notifications.notification_customer_refunded_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentHandedStatusInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentHandedStatusInternalUser" aria-expanded="true" aria-controls="collapseCashPaymentHandedStatusInternalUser">
                                    <span class="text-capitalize notification-title">Cash payment handed status internal user</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentHandedStatusInternalUser" class="collapse" aria-labelledby="notificationCashPaymentHandedStatusInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentHandedStatusInternalUserNotification 
                                    :notification="notifications.notification_cash_payment_handed_status_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentDeclinedStatusInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentDeclinedStatusInternalUser" aria-expanded="true" aria-controls="collapseCashPaymentDeclinedStatusInternalUser">
                                    <span class="text-capitalize notification-title">Cash payment declined status internal user</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentDeclinedStatusInternalUser" class="collapse" aria-labelledby="notificationCashPaymentDeclinedStatusInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentDeclinedInternalUserNotification 
                                    :notification="notifications.notification_cash_payment_declined_status_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderInvoiceRequestInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderInvoiceRequestInternalUser" aria-expanded="true" aria-controls="collapseOrderInvoiceRequestInternalUser">
                                    <span class="text-capitalize notification-title">Order invoice request internal user</span>
                                </button>
                            </div>
                            <div id="collapseOrderInvoiceRequestInternalUser" class="collapse" aria-labelledby="notificationOrderInvoiceRequestInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderInvoiceRequestInternalUserNotification 
                                    :notification="notifications.notification_order_invoice_request_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderInvoiceRequestCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderInvoiceRequestCustomer" aria-expanded="true" aria-controls="collapseOrderInvoiceRequestCustomer">
                                    <span class="text-capitalize notification-title">Order invoice request customer</span>
                                </button>
                            </div>
                            <div id="collapseOrderInvoiceRequestCustomer" class="collapse" aria-labelledby="notificationOrderInvoiceRequestCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderInvoiceRequestCustomerNotification 
                                    :notification="notifications.notification_order_invoice_request_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentPendingStatusHandedTo">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentPendingStatusHandedTo" aria-expanded="true" aria-controls="collapseCashPaymentPendingStatusHandedTo">
                                    <span class="text-capitalize notification-title">Cash payment pending status handed to</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentPendingStatusHandedTo" class="collapse" aria-labelledby="notificationCashPaymentPendingStatusHandedTo" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentPendingStatusHandedToNotification 
                                    :notification="notifications.notification_cash_payment_pending_status_handed_to" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentHandedStatusHandedTo">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentHandedStatusHandedTo" aria-expanded="true" aria-controls="collapseCashPaymentHandedStatusHandedTo">
                                    <span class="text-capitalize notification-title">Cash payment handed status handed to</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentHandedStatusHandedTo" class="collapse" aria-labelledby="notificationCashPaymentHandedStatusHandedTo" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentHandedStatusHandedToNotification 
                                    :notification="notifications.notification_cash_payment_handed_status_handed_to" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCashPaymentDeclinedStatusHandedTo">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCashPaymentDeclinedStatusHandedTo" aria-expanded="true" aria-controls="collapseCashPaymentDeclinedStatusHandedTo">
                                    <span class="text-capitalize notification-title">Cash payment declined status handed to</span>
                                </button>
                            </div>
                            <div id="collapseCashPaymentDeclinedStatusHandedTo" class="collapse" aria-labelledby="notificationCashPaymentDeclinedStatusHandedTo" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CashPaymentDeclinedStatusHandedToNotification 
                                    :notification="notifications.notification_cash_payment_declined_status_handed_to" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationLeaveApplicationCreationInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseLeaveApplicationCreationInternalUser" aria-expanded="true" aria-controls="collapseLeaveApplicationCreationInternalUser">
                                    <span class="text-capitalize notification-title">Leave application creation internal user</span>
                                </button>
                            </div>
                            <div id="collapseLeaveApplicationCreationInternalUser" class="collapse" aria-labelledby="notificationLeaveApplicationCreationInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <LeaveApplicationCreationInternalUserNotification 
                                    :notification="notifications.notification_leave_application_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationLeaveApplicationStatusUpdateInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseLeaveApplicationStatusUpdateInternalUser" aria-expanded="true" aria-controls="collapseLeaveApplicationStatusUpdateInternalUser">
                                    <span class="text-capitalize notification-title">Leave application status update internal user</span>
                                </button>
                            </div>
                            <div id="collapseLeaveApplicationStatusUpdateInternalUser" class="collapse" aria-labelledby="notificationLeaveApplicationStatusUpdateInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <LeaveApplicationStatusUpdateInternalUserNotification 
                                    :notification="notifications.notification_leave_application_status_update_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationLeaveApplicationStatusUpdateTechnician">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseLeaveApplicationStatusUpdateTechnician" aria-expanded="true" aria-controls="collapseLeaveApplicationStatusUpdateTechnician">
                                    <span class="text-capitalize notification-title">Leave application status update technician</span>
                                </button>
                            </div>
                            <div id="collapseLeaveApplicationStatusUpdateTechnician" class="collapse" aria-labelledby="notificationLeaveApplicationStatusUpdateTechnician" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <LeaveApplicationStatusUpdateTechnicianNotification 
                                    :notification="notifications.notification_leave_application_status_update_technician" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAfterpayPaymentCreationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAfterpayPaymentCreationCustomer" aria-expanded="true" aria-controls="collapseAfterpayPaymentCreationCustomer">
                                    <span class="text-capitalize notification-title">Afterpay payment creation customer</span>
                                </button>
                            </div>
                            <div id="collapseAfterpayPaymentCreationCustomer" class="collapse" aria-labelledby="notificationAfterpayPaymentCreationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AfterpayPaymentCreationCustomerNotification 
                                    :notification="notifications.notification_afterpay_payment_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAfterpayPaymentCreationInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAfterpayPaymentCreationInternalUser" aria-expanded="true" aria-controls="collapseAfterpayPaymentCreationInternalUser">
                                    <span class="text-capitalize notification-title">Afterpay payment creation internal user</span>
                                </button>
                            </div>
                            <div id="collapseAfterpayPaymentCreationInternalUser" class="collapse" aria-labelledby="notificationAfterpayPaymentCreationInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AfterpayPaymentCreationInternalUserNotification 
                                    :notification="notifications.notification_afterpay_payment_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAfterpayPaymentStatusUpdateCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAfterpayPaymentStatusUpdateCustomer" aria-expanded="true" aria-controls="collapseAfterpayPaymentStatusUpdateCustomer">
                                    <span class="text-capitalize notification-title">Afterpay payment status update customer</span>
                                </button>
                            </div>
                            <div id="collapseAfterpayPaymentStatusUpdateCustomer" class="collapse" aria-labelledby="notificationAfterpayPaymentStatusUpdateCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AfterpayPaymentStatusUpdateCustomerNotification 
                                    :notification="notifications.notification_afterpay_payment_status_update_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAfterpayPaymentStatusUpdateInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAfterpayPaymentStatusUpdateInternalUser" aria-expanded="true" aria-controls="collapseAfterpayPaymentStatusUpdateInternalUser">
                                    <span class="text-capitalize notification-title">Afterpay payment status update internal user</span>
                                </button>
                            </div>
                            <div id="collapseAfterpayPaymentStatusUpdateInternalUser" class="collapse" aria-labelledby="notificationAfterpayPaymentStatusUpdateInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AfterpayPaymentStatusUpdateInternalUserNotification 
                                    :notification="notifications.notification_afterpay_payment_status_update_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCustomerOpinionCheckInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCustomerOpinionCheckInternalUser" aria-expanded="true" aria-controls="collapseCustomerOpinionCheckInternalUser">
                                    <span class="text-capitalize notification-title">Customer opinion check internal user</span>
                                </button>
                            </div>
                            <div id="collapseCustomerOpinionCheckInternalUser" class="collapse" aria-labelledby="notificationCustomerOpinionCheckInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CustomerOpinionCheckInternalUserNotification 
                                    :notification="notifications.notification_customer_opinion_check_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentPaymentRequestCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentPaymentRequestCustomer" aria-expanded="true" aria-controls="collapseAppointmentPaymentRequestCustomer">
                                    <span class="text-capitalize notification-title">Appointment payment request customer</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentPaymentRequestCustomer" class="collapse" aria-labelledby="notificationAppointmentPaymentRequestCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentPaymentRequestCustomerNotification 
                                    :notification="notifications.notification_appointment_payment_request_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderPaymentRequestCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderPaymentRequestCustomer" aria-expanded="true" aria-controls="collapseOrderPaymentRequestCustomer">
                                    <span class="text-capitalize notification-title">Order payment request customer</span>
                                </button>
                            </div>
                            <div id="collapseOrderPaymentRequestCustomer" class="collapse" aria-labelledby="notificationOrderPaymentRequestCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderPaymentRequestCustomerNotification 
                                    :notification="notifications.notification_order_payment_request_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCallRequestAcknowledgementInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCallRequestAcknowledgementInternalUser" aria-expanded="true" aria-controls="collapseCallRequestAcknowledgementInternalUser">
                                    <span class="text-capitalize notification-title">Call request acknowledgement internal user</span>
                                </button>
                            </div>
                            <div id="collapseCallRequestAcknowledgementInternalUser" class="collapse" aria-labelledby="notificationCallRequestAcknowledgementInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CallRequestAcknowledgementInternalUserNotification 
                                    :notification="notifications.notification_call_request_acknowledgement_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentStartedScheduleInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentStartedScheduleInternalUser" aria-expanded="true" aria-controls="collapseAppointmentStartedScheduleInternalUser">
                                    <span class="text-capitalize notification-title">Appointment started schedule internal user</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentStartedScheduleInternalUser" class="collapse" aria-labelledby="notificationAppointmentStartedScheduleInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentStartedScheduleInternalUserNotification 
                                    :notification="notifications.notification_appointment_started_schedule_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentEndedScheduleInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentEndedScheduleInternalUser" aria-expanded="true" aria-controls="collapseAppointmentEndedScheduleInternalUser">
                                    <span class="text-capitalize notification-title">Appointment ended schedule internal user</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentEndedScheduleInternalUser" class="collapse" aria-labelledby="notificationAppointmentEndedScheduleInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentEndedScheduleInternalUserNotification 
                                    :notification="notifications.notification_appointment_ended_schedule_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentNoteCreationInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentNoteCreationInternalUser" aria-expanded="true" aria-controls="collapseAppointmentNoteCreationInternalUser">
                                    <span class="text-capitalize notification-title">Appointment note creation internal user</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentNoteCreationInternalUser" class="collapse" aria-labelledby="notificationAppointmentNoteCreationInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentNoteCreationInternalUserNotification 
                                    :notification="notifications.notification_appointment_note_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentNoteCreationTechnician">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentNoteCreationTechnician" aria-expanded="true" aria-controls="collapseAppointmentNoteCreationTechnician">
                                    <span class="text-capitalize notification-title">Appointment note creation technician</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentNoteCreationTechnician" class="collapse" aria-labelledby="notificationAppointmentNoteCreationTechnician" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentNoteCreationTechnicianNotification 
                                    :notification="notifications.notification_appointment_note_creation_technician" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentNoteCreationCreator">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentNoteCreationCreator" aria-expanded="true" aria-controls="collapseAppointmentNoteCreationCreator">
                                    <span class="text-capitalize notification-title">Appointment note creation creator</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentNoteCreationCreator" class="collapse" aria-labelledby="notificationAppointmentNoteCreationCreator" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentNoteCreationCreatorNotification 
                                    :notification="notifications.notification_appointment_note_creation_creator" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentNoteCreationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentNoteCreationCustomer" aria-expanded="true" aria-controls="collapseAppointmentNoteCreationCustomer">
                                    <span class="text-capitalize notification-title">Appointment note creation customer</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentNoteCreationCustomer" class="collapse" aria-labelledby="notificationAppointmentNoteCreationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentNoteCreationCustomerNotification 
                                    :notification="notifications.notification_appointment_note_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentDelayWarningInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentDelayWarningInternalUser" aria-expanded="true" aria-controls="collapseAppointmentDelayWarningInternalUser">
                                    <span class="text-capitalize notification-title">Appointment delay warning internal user</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentDelayWarningInternalUser" class="collapse" aria-labelledby="notificationAppointmentDelayWarningInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentDelayWarningInternalUserNotification 
                                    :notification="notifications.notification_appointment_delay_warning_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationLoyaltyPointRegistrationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseLoyaltyPointRegistrationCustomer" aria-expanded="true" aria-controls="collapseLoyaltyPointRegistrationCustomer">
                                    <span class="text-capitalize notification-title">Loyalty point registration customer</span>
                                </button>
                            </div>
                            <div id="collapseLoyaltyPointRegistrationCustomer" class="collapse" aria-labelledby="notificationLoyaltyPointRegistrationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <LoyaltyPointRegistrationCustomerNotification 
                                    :notification="notifications.notification_loyalty_point_registration_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationLoyaltyPointReferralCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseLoyaltyPointReferralCustomer" aria-expanded="true" aria-controls="collapseLoyaltyPointReferralCustomer">
                                    <span class="text-capitalize notification-title">Loyalty point referral customer</span>
                                </button>
                            </div>
                            <div id="collapseLoyaltyPointReferralCustomer" class="collapse" aria-labelledby="notificationLoyaltyPointReferralCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <LoyaltyPointReferralCustomerNotification 
                                    :notification="notifications.notification_loyalty_point_referral_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationLoyaltyPointSystemUsageCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseLoyaltyPointSystemUsageCustomer" aria-expanded="true" aria-controls="collapseLoyaltyPointSystemUsageCustomer">
                                    <span class="text-capitalize notification-title">Loyalty point system usage customer</span>
                                </button>
                            </div>
                            <div id="collapseLoyaltyPointSystemUsageCustomer" class="collapse" aria-labelledby="notificationLoyaltyPointSystemUsageCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <LoyaltyPointSystemUsageCustomerNotification 
                                    :notification="notifications.notification_loyalty_point_system_usage_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentScheduleDisobeyInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentScheduleDisobeyInternalUser" aria-expanded="true" aria-controls="collapseAppointmentScheduleDisobeyInternalUser">
                                    <span class="text-capitalize notification-title">Appointment schedule disobey internal user</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentScheduleDisobeyInternalUser" class="collapse" aria-labelledby="notificationAppointmentScheduleDisobeyInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentScheduleDisobeyInternalUserNotification 
                                    :notification="notifications.notification_appointment_schedule_disobey_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationAppointmentScheduleDisobeyTechnician">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseAppointmentScheduleDisobeyTechnician" aria-expanded="true" aria-controls="collapseAppointmentScheduleDisobeyTechnician">
                                    <span class="text-capitalize notification-title">Appointment schedule disobey technician</span>
                                </button>
                            </div>
                            <div id="collapseAppointmentScheduleDisobeyTechnician" class="collapse" aria-labelledby="notificationAppointmentScheduleDisobeyTechnician" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <AppointmentScheduleDisobeyTechnicianNotification 
                                    :notification="notifications.notification_appointment_schedule_disobey_technician" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCustomerOpinionPositive">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCustomerOpinionPositive" aria-expanded="true" aria-controls="collapseCustomerOpinionPositive">
                                    <span class="text-capitalize notification-title">Thanks for customer positive opinion</span>
                                </button>
                            </div>
                            <div id="collapseCustomerOpinionPositive" class="collapse" aria-labelledby="notificationCustomerOpinionPositive" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CustomerPositiveOpinionNotification 
                                    :notification="notifications.notification_thanks_for_share_customer_positive_opinion" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCustomerOpinionNegative">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCustomerOpinionNegative" aria-expanded="true" aria-controls="collapseCustomerOpinionNegative">
                                    <span class="text-capitalize notification-title">Thanks for customer negative opinion</span>
                                </button>
                            </div>
                            <div id="collapseCustomerOpinionNegative" class="collapse" aria-labelledby="notificationCustomerOpinionNegative" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CustomerNegativeOpinionNotification 
                                    :notification="notifications.notification_thanks_for_share_customer_negative_opinion" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderNoteCreationInternalUser">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderNoteCreationInternalUser" aria-expanded="true" aria-controls="collapseOrderNoteCreationInternalUser">
                                    <span class="text-capitalize notification-title">Order note creation internal user</span>
                                </button>
                            </div>
                            <div id="collapseOrderNoteCreationInternalUser" class="collapse" aria-labelledby="notificationOrderNoteCreationInternalUser" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderNoteCreationInternalUserNotification 
                                    :notification="notifications.notification_order_note_creation_internal_user" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderNoteCreationTechnician">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderNoteCreationTechnician" aria-expanded="true" aria-controls="collapseOrderNoteCreationTechnician">
                                    <span class="text-capitalize notification-title">Order note creation technician</span>
                                </button>
                            </div>
                            <div id="collapseOrderNoteCreationTechnician" class="collapse" aria-labelledby="notificationOrderNoteCreationTechnician" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderNoteCreationTechnicianNotification 
                                    :notification="notifications.notification_order_note_creation_technician" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderNoteCreationCreator">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderNoteCreationCreator" aria-expanded="true" aria-controls="collapseOrderNoteCreationCreator">
                                    <span class="text-capitalize notification-title">Order note creation creator</span>
                                </button>
                            </div>
                            <div id="collapseOrderNoteCreationCreator" class="collapse" aria-labelledby="notificationOrderNoteCreationCreator" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderNoteCreationCreatorNotification 
                                    :notification="notifications.notification_order_note_creation_creator" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationOrderNoteCreationCustomer">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseOrderNoteCreationCustomer" aria-expanded="true" aria-controls="collapseOrderNoteCreationCustomer">
                                    <span class="text-capitalize notification-title">Order note creation customer</span>
                                </button>
                            </div>
                            <div id="collapseOrderNoteCreationCustomer" class="collapse" aria-labelledby="notificationOrderNoteCreationCustomer" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <OrderNoteCreationCustomerNotification 
                                    :notification="notifications.notification_order_note_creation_customer" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="notificationCouponMarketing">
                                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                                    data-target="#collapseCouponMarketing" aria-expanded="true" aria-controls="collapseCouponMarketing">
                                    <span class="text-capitalize notification-title">Coupon marketing</span>
                                </button>
                            </div>
                            <div id="collapseCouponMarketing" class="collapse" aria-labelledby="notificationCouponMarketing" data-parent="#accordionNotification">
                                <div class="card-body">
                                    <CouponMarketingNotification 
                                    :notification="notifications.notification_coupon_marketing" 
                                    :viewExampleEmailTemplateHandler="viewExampleEmailTemplateHandler"
                                    :viewExampleSmsTemplateHandler="viewExampleSmsTemplateHandler" 
                                    :emailTemplates="emailTemplates"
                                    :smsTemplates="smsTemplates"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="" data-toggle="modal" data-target="#exampleEmailTemplate"></div>
                <div class="" data-toggle="modal" data-target="#exampleSmsTemplate"></div>
                <ExampleEmailTemplate :exampleEmailTemplate="currentEmailTemplate?.example_view ?? ''" />
                <ExampleSmsTemplate :exampleSmsTemplate="currentSmsTemplate?.example_view ?? ''" />
            </div>
        </template>
    </AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
import ExampleEmailTemplate from "@/views/backEnd/settings/notification/includes/ExampleEmailTemplate";
import ExampleSmsTemplate from "@/views/backEnd/settings/notification/includes/ExampleSmsTemplate";
import ForgetPasswordNotification from "./includes/ForgetPasswordNotification.vue";
import CustomerCredentialNotification from "./includes/CustomerCredentialNotification.vue";
import InternalUserCredentialNotification from "./includes/InternalUserCredentialNotification.vue";
import ContactRequestAcknowledgementRequesterNotification from "./includes/ContactRequestAcknowledgementRequesterNotification.vue";
import ContactRequestAcknowledgementInternalUserNotification from "./includes/ContactRequestAcknowledgementInternalUserNotification.vue";
import AppointmentCreateCustomerNotification from "./includes/AppointmentCreationCustomerNotification.vue";
import AppointmentCreateInternalUserNotification from "./includes/AppointmentCreationInternalUserNotification.vue";
import AppointmentCreateTechNotification from "./includes/AppointmentCreationTechNotification.vue";
import AppointmentStatusUpdateCustomerNotification from "./includes/AppointmentStatusUpdateCustomerNotification.vue";
import AppointmentStatusUpdateInternalUserNotification from "./includes/AppointmentStatusUpdateInternalUserNotification.vue";
import AppointmentStatusUpdateTechnicianNotification from "./includes/AppointmentStatusUpdateTechnicianNotification.vue";
import OrderCreationCustomerNotification from "./includes/OrderCreationCustomerNotification.vue";
import OrderCreationInternalUserNotification from "./includes/OrderCreationInternalUserNotification.vue";
import OrderStatusUpdateCustomerNotification from "./includes/OrderStatusUpdateCustomerNotification.vue";
import OrderStatusUpdateInternalUserNotification from "./includes/OrderStatusUpdateInternalUserNotification.vue";
import AppointmentCreditedCustomerNotification from "./includes/AppointmentCreditedCustomerNotification.vue";
import AppointmentCreditedInternalUserNotification from "./includes/AppointmentCreditedInternalUserNotification.vue";
import RefundInternalUserNotification from "./includes/RefundInternalUserNotification.vue";
import CashPaymentCreationCustomerNotification from "./includes/CashPaymentCreationCustomerNotification.vue";
import CashPaymentCreationInternalUserNotification from "./includes/CashPaymentCreationInternalUserNotification.vue";
import CardPaymentCreationCustomerNotification from "./includes/CardPaymentCreationCustomerNotification.vue";
import CardPaymentCreationInternalUserNotification from "./includes/CardPaymentCreationInternalUserNotification.vue";
import EftPaymentCreationCustomerNotification from "./includes/EftPaymentCreationCustomerNotification.vue";
import EftPaymentCreationInternalUserNotification from "./includes/EftPaymentCreationInternalUserNotification.vue";
import CardPaymentStatusUpdateCustomerNotification from "./includes/CardPaymentStatusUpdateCustomerNotification.vue";
import CardPaymentStatusUpdateInternalUserNotification from "./includes/CardPaymentStatusUpdateInternalUserNotification.vue";
import CashPaymentPendingStatusUpdateInternalUserNotification from "./includes/CashPaymentPendingStatusUpdateInternalUserNotification.vue";
import ShareCustomerOpinionNotification from "./includes/ShareCustomerOpinionNotification.vue";
import ServiceAgreementCustomerNotification from "./includes/ServiceAgreementCustomerNotification.vue";
import ServiceAgreementInternalUserNotification from "./includes/ServiceAgreementInternalUserNotification.vue";
import AppointmentDeliveredInternalUserNotification from "./includes/AppointmentDeliveredInternalUserNotification.vue";
import AppointmentDeliveredCustomerNotification from "./includes/AppointmentDeliveredCustomerNotification.vue";
import AppointmentDeliveredTechnicianNotification from "./includes/AppointmentDeliveredTechnicianNotification.vue";
import AppointmentInvoiceRequestInternalUserNotification from "./includes/AppointmentInvoiceRequestInternalUserNotification.vue";
import AppointmentInvoiceRequestCustomerNotification from "./includes/AppointmentInvoiceRequestCustomerNotification.vue";
import SubscriptionRenewalInternalUserNotification from "./includes/SubscriptionRenewalInternalUserNotification.vue";
import SubscriptionRenewalCustomerNotification from "./includes/SubscriptionRenewalCustomerNotification.vue";
import RefundStatusUpdateInternalUserNotification from "./includes/RefundStatusUpdateInternalUserNotification.vue";
import CustomerRefundedInternalUserNotification from "./includes/CustomerRefundedInternalUserNotification.vue";
import CustomerRefundedCustomerNotification from "./includes/CustomerRefundedCustomerNotification.vue";
import CashPaymentHandedStatusInternalUserNotification from "./includes/CashPaymentHandedStatusInternalUserNotification.vue";
import CashPaymentDeclinedInternalUserNotification from "./includes/CashPaymentDeclinedInternalUserNotification.vue";
import OrderInvoiceRequestInternalUserNotification from "./includes/OrderInvoiceRequestInternalUserNotification.vue";
import OrderInvoiceRequestCustomerNotification from "./includes/OrderInvoiceRequestCustomerNotification.vue";
import CashPaymentPendingStatusHandedToNotification from "./includes/CashPaymentPendingStatusHandedToNotification.vue";
import CashPaymentHandedStatusHandedToNotification from "./includes/CashPaymentHandedStatusHandedToNotification.vue";
import CashPaymentDeclinedStatusHandedToNotification from "./includes/CashPaymentDeclinedStatusHandedToNotification.vue";
import LeaveApplicationCreationInternalUserNotification from "./includes/LeaveApplicationCreationInternalUserNotification.vue";
import LeaveApplicationStatusUpdateInternalUserNotification from "./includes/LeaveApplicationStatusUpdateInternalUserNotification.vue";
import LeaveApplicationStatusUpdateTechnicianNotification from "./includes/LeaveApplicationStatusUpdateTechnicianNotification.vue";
import AfterpayPaymentCreationCustomerNotification from "./includes/AfterpayPaymentCreationCustomerNotification.vue";
import AfterpayPaymentCreationInternalUserNotification from "./includes/AfterpayPaymentCreationInternalUserNotification.vue";
import AfterpayPaymentStatusUpdateCustomerNotification from "./includes/AfterpayPaymentStatusUpdateCustomerNotification.vue";
import AfterpayPaymentStatusUpdateInternalUserNotification from "./includes/AfterpayPaymentStatusUpdateInternalUserNotification.vue";
import CustomerOpinionCheckInternalUserNotification from "./includes/CustomerOpinionCheckInternalUserNotification.vue";
import AppointmentPaymentRequestCustomerNotification from "./includes/AppointmentPaymentRequestCustomerNotification.vue";
import OrderPaymentRequestCustomerNotification from "./includes/OrderPaymentRequestCustomerNotification.vue";
import CallRequestAcknowledgementInternalUserNotification from "./includes/CallRequestAcknowledgementInternalUserNotification.vue";
import AppointmentStartedScheduleInternalUserNotification from "./includes/AppointmentStartedScheduleInternalUserNotification.vue";
import AppointmentEndedScheduleInternalUserNotification from "./includes/AppointmentEndedScheduleInternalUserNotification.vue";
import AppointmentNoteCreationInternalUserNotification from "./includes/AppointmentNoteCreationInternalUserNotification.vue";
import AppointmentNoteCreationTechnicianNotification from "./includes/AppointmentNoteCreationTechnicianNotification.vue";
import AppointmentNoteCreationCreatorNotification from "./includes/AppointmentNoteCreationCreatorNotification.vue";
import AppointmentNoteCreationCustomerNotification from "./includes/AppointmentNoteCreationCustomerNotification.vue";
import AppointmentDelayWarningInternalUserNotification from "./includes/AppointmentDelayWarningInternalUserNotification.vue";
import LoyaltyPointRegistrationCustomerNotification from "./includes/LoyaltyPointRegistrationCustomerNotification.vue";
import LoyaltyPointReferralCustomerNotification from "./includes/LoyaltyPointReferralCustomerNotification.vue";
import LoyaltyPointSystemUsageCustomerNotification from "./includes/LoyaltyPointSystemUsageCustomerNotification.vue";
import AppointmentScheduleDisobeyInternalUserNotification from "./includes/AppointmentScheduleDisobeyInternalUserNotification.vue";
import AppointmentScheduleDisobeyTechnicianNotification from "./includes/AppointmentScheduleDisobeyTechnicianNotification.vue";
import CustomerPositiveOpinionNotification from "./includes/CustomerPositiveOpinionNotification.vue";
import CustomerNegativeOpinionNotification from "./includes/CustomerNegativeOpinionNotification.vue";
import OrderNoteCreationInternalUserNotification from "./includes/OrderNoteCreationInternalUserNotification.vue";
import OrderNoteCreationTechnicianNotification from "./includes/OrderNoteCreationTechnicianNotification.vue";
import OrderNoteCreationCreatorNotification from "./includes/OrderNoteCreationCreatorNotification.vue";
import OrderNoteCreationCustomerNotification from "./includes/OrderNoteCreationCustomerNotification.vue";
import CouponMarketingNotification from "./includes/CouponMarketingNotification.vue";

// core packages
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    name: "NotificationSetting",
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        AppLayout,
        ExampleEmailTemplate,
        ExampleSmsTemplate,
        ForgetPasswordNotification,
        CustomerCredentialNotification,
        InternalUserCredentialNotification,
        ContactRequestAcknowledgementRequesterNotification,
        ContactRequestAcknowledgementInternalUserNotification,
        AppointmentCreateCustomerNotification,
        AppointmentCreateInternalUserNotification,
        AppointmentCreateTechNotification,
        AppointmentStatusUpdateCustomerNotification,
        AppointmentStatusUpdateInternalUserNotification,
        AppointmentStatusUpdateTechnicianNotification,
        OrderCreationCustomerNotification,
        OrderCreationInternalUserNotification,
        OrderStatusUpdateCustomerNotification,
        OrderStatusUpdateInternalUserNotification,
        AppointmentCreditedCustomerNotification,
        AppointmentCreditedInternalUserNotification,
        RefundInternalUserNotification,
        CashPaymentCreationCustomerNotification,
        CashPaymentCreationInternalUserNotification,
        CardPaymentCreationCustomerNotification,
        CardPaymentCreationInternalUserNotification,
        EftPaymentCreationCustomerNotification,
        EftPaymentCreationInternalUserNotification,
        CardPaymentStatusUpdateCustomerNotification,
        CardPaymentStatusUpdateInternalUserNotification,
        CashPaymentPendingStatusUpdateInternalUserNotification,
        ShareCustomerOpinionNotification,
        ServiceAgreementCustomerNotification,
        ServiceAgreementInternalUserNotification,
        AppointmentDeliveredInternalUserNotification,
        AppointmentDeliveredCustomerNotification,
        AppointmentDeliveredTechnicianNotification,
        AppointmentInvoiceRequestInternalUserNotification,
        AppointmentInvoiceRequestCustomerNotification,
        SubscriptionRenewalInternalUserNotification,
        SubscriptionRenewalCustomerNotification,
        RefundStatusUpdateInternalUserNotification,
        CustomerRefundedInternalUserNotification,
        CustomerRefundedCustomerNotification,
        CashPaymentHandedStatusInternalUserNotification,
        CashPaymentDeclinedInternalUserNotification,
        OrderInvoiceRequestInternalUserNotification,
        OrderInvoiceRequestCustomerNotification,
        CashPaymentPendingStatusHandedToNotification,
        CashPaymentHandedStatusHandedToNotification,
        CashPaymentDeclinedStatusHandedToNotification,
        LeaveApplicationCreationInternalUserNotification,
        LeaveApplicationStatusUpdateInternalUserNotification,
        LeaveApplicationStatusUpdateTechnicianNotification,
        AfterpayPaymentCreationCustomerNotification,
        AfterpayPaymentCreationInternalUserNotification,
        AfterpayPaymentStatusUpdateCustomerNotification,
        AfterpayPaymentStatusUpdateInternalUserNotification,
        CustomerOpinionCheckInternalUserNotification,
        AppointmentPaymentRequestCustomerNotification,
        OrderPaymentRequestCustomerNotification,
        CallRequestAcknowledgementInternalUserNotification,
        AppointmentStartedScheduleInternalUserNotification,
        AppointmentEndedScheduleInternalUserNotification,
        AppointmentNoteCreationInternalUserNotification,
        AppointmentNoteCreationTechnicianNotification,
        AppointmentNoteCreationCreatorNotification,
        AppointmentNoteCreationCustomerNotification,
        AppointmentDelayWarningInternalUserNotification,
        LoyaltyPointRegistrationCustomerNotification,
        LoyaltyPointReferralCustomerNotification,
        LoyaltyPointSystemUsageCustomerNotification,
        AppointmentScheduleDisobeyInternalUserNotification,
        AppointmentScheduleDisobeyTechnicianNotification,
        CustomerPositiveOpinionNotification,
        CustomerNegativeOpinionNotification,
        OrderNoteCreationInternalUserNotification,
        OrderNoteCreationTechnicianNotification,
        OrderNoteCreationCreatorNotification,
        OrderNoteCreationCustomerNotification,
        CouponMarketingNotification
    },

    data() {
        return {
            modelIdForDelete: '',
            getSettingsParams: {
                type: ['notification'],
            },
            getNotifications: [],
            notifications: {},
            currentSetting: {},
            currentEmailTemplate: {},
            currentSmsTemplate: {}
        };
    },
    computed: {
        ...mapGetters({
            emailTemplates: "appEmailTemplates/emailTemplates",
            smsTemplates: "appSmsTemplates/smsTemplates",
        }),

    },
    watch: {

    },
    methods: {
        ...mapActions({
            getSettings: "appSettings/getSettings",
            getEmailTemplates: "appEmailTemplates/getEmailTemplates",
            getSmsTemplates: "appSmsTemplates/getSmsTemplates",
        }),

        async getSettingList() {
            this.loader(true);
            const response = await this.getSettings(this.getSettingsParams);
            if (response.status == 200) {
                this.loader(false);
                this.getNotifications = response.data.data;
                return;
            }
            this.loader(false);

        },
        async getEmailTemplateList() {
            await this.getEmailTemplates({
                order_by_name: 'ASC'
            });
        },
        async getSmsTemplateList() {
            await this.getSmsTemplates();
        },
        updateNotificationSetting(settingObj) {
            this.currentSetting = {};
            this.currentSetting = settingObj;
            document.querySelector('[data-target="#updateNotification"]').click();
        },

        viewExampleEmailTemplateHandler(templateId) {
            this.loader(true);
            this.currentEmailTemplate = {};
            const emailTemplateObj = this.emailTemplates.find((emailTemplate) => templateId == emailTemplate.id);
            if (emailTemplateObj !== undefined) {
                this.currentEmailTemplate = emailTemplateObj;
                this.currentSmsTemplate = emailTemplateObj;

                this.loader(false);
                document.querySelector('[data-target="#exampleEmailTemplate"]').click();

            } else {
                this.loader(false);
                this.showToastMessage({
                    type: 'error',
                    message: 'something went wrong'
                });
            }
        },
        viewExampleSmsTemplateHandler(templateId) {
            this.loader(true);
            this.currentSmsTemplate = {};
            const smsTemplateObj = this.smsTemplates.find((smsTemplate) => templateId == smsTemplate.id);
            if (smsTemplateObj !== undefined) {
                this.currentSmsTemplate = smsTemplateObj;

                this.loader(false);
                document.querySelector('[data-target="#exampleSmsTemplate"]').click();
            } else {
                this.loader(false);
                this.showToastMessage({
                    type: 'error',
                    message: 'something went wrong'
                });
            }
        },

    },

    async mounted() {
        this.loader(true);
        await this.getSettingList();
        await this.getEmailTemplateList();
        await this.getSmsTemplateList();
        this.loader(false);
        this.getNotifications.map(notification => {
            this.notifications[Object.keys(notification)[0]] = notification[Object.keys(notification)[0]];
        })
        // console.log(this.notifications.notification_coupon_marketing, 'note')
    },
};
</script>

<style>
.notification-wrapper .accordion .card{
    overflow: visible !important;
}
.notification-title{
    font-size: 16px;
    color: rgb(44, 44, 44);
}
</style>
