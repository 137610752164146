<template>
  <div>
    <div class="px-2">
      <div class="d-flex align-items-center mb-2">
        <h5 class="mb-0">Email</h5>
        <div class="ml-1">
          <button class="btn view-example-btn"
            @click="this.viewExampleEmailTemplateHandler(emailNotification.templateId)">
            View Example
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label>Sender Name</label>
              <input type="text" v-model="emailNotification.senderName" class="form-control" placeholder="" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label>subject</label>
              <input type="text" v-model="emailNotification.subject" class="form-control" placeholder="" />

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="multiselect-container">
              <label>Selected Template</label>
              <VueMultiselect v-model="selectedEmailTemplate" :allow-empty="false" class="multiselect-blue"
                :options="emailTemplates" :close-on-select="true" placeholder="Select a status" label="name" track-by="id"
                :show-labels="false" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label>Template Name</label>
              <input type="text" v-model="emailNotification.templateName" class="form-control" placeholder="" readonly/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label>Sender Email Address</label>
              <input type="text" v-model="emailNotification.senderEmailAddress" class="form-control" placeholder="" />

            </div>
          </div>
        </div>
        <div v-if="emailNotification.hasRecipient" class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label>Recipient</label>
              <input type="text" v-model="emailNotification.recipientValue" class="form-control" placeholder="" />

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-if="emailNotification.hasCcRecipients">
          <div class="form-group">
            <div class="controls">
              <label>CC recipients (Enter all values by coma separate)</label>
              <textarea v-model="emailNotification.ccRecipientsValue" name="" id="" cols="30" rows="5"
                class="form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="emailNotification.hasBccRecipients">
          <div class="form-group">
            <div class="controls">
              <label>BCC recipients (Enter all values by coma separate)</label>
              <textarea v-model="emailNotification.bccRecipientsValue" name="" id="" cols="30" rows="5"
                class="form-control"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedSmsTemplate?.id">
        <div class="d-flex align-items-center mb-2 mt-2">
          <h5 class="mb-0">SMS</h5>
          <div class="ml-1">
            <button class="btn view-example-btn" @click="viewExampleSmsTemplateHandler(emailNotification.templateId)">
              View Example
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label>Sender Name</label>
                <input type="text" v-model="smsNotification.senderName" class="form-control" placeholder="" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label>Template Name</label>
                <input type="text" v-model="smsNotification.templateName" class="form-control" placeholder="" readonly/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label>Selected Template</label>
                <VueMultiselect v-model="selectedSmsTemplate" :allow-empty="false" class="multiselect-blue"
                  :options="smsTemplates" :close-on-select="true" placeholder="Select a status" label="name" track-by="id"
                  :show-labels="false" />
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="smsNotification.hasStatus">
            <div class="form-group">
              <div class="controls">
                <label>Status</label>
                <div style="margin-top: .5rem">
                  <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                    <div>
                      <input v-model="smsNotification.statusValue" type="checkbox" class="custom-control-input"
                        id="AppointmentUpdateTechSmsStatus">
                      <label class="custom-control-label mr-1" for="AppointmentUpdateTechSmsStatus"></label>
                    </div>
                    <span class="font-medium-1">{{ (smsNotification.statusValue) == true ? "Active" : "Inactive" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="smsNotification.hasRecipients">
          <div class="col-12">
            <div class="form-group">
              <div class="controls">
                <label>Recipients</label>
                <textarea v-model="smsNotification.recipientsValue" name="" id="" cols="30" rows="5"
                  class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-0 pt-0 text-right">
      <button type="button" :disabled="!can('setting-notification-update')" class="btn btn-primary" @click="updateSetting">
        <i class="bx bx-x d-block d-sm-none"></i>
        <span class="d-none d-sm-block">Save Changes</span>
      </button>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import { mapActions } from "vuex";
export default {
  name: 'AppointmentStatusUpdateTechnicianNotification',
  props: [
    'notification',
    'viewExampleEmailTemplateHandler',
    'viewExampleSmsTemplateHandler',
    'emailTemplates',
    'smsTemplates'
  ],
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      id: '',
      key: '',
      type: 'notification',
      selectedEmailTemplate: {},
      selectedSmsTemplate: {},

      emailNotification: {
        senderEmailAddress: '',
        senderName: '',
        subject: '',
        templateId: '',
        templateName: '',
        hasRecipient: false,
        hasCcRecipients: false,
        hasBccRecipients: false,
        recipient: '',
        ccRecipientsValue: '',
        bccRecipientsValue: '',
        recipientValue: ''
      },
      smsNotification: {
        senderName: '',
        templateId: '',
        templateName: '',
        hasRecipients: false,
        hasStatus: false,
        recipientsValue: '',
        statusValue: false,
      },
      currentEmailTemplate: {},
      currentSmsTemplate: {}
    }
  },
  watch: {
    notification(currentNotification) {
      // value reset 
      this.emailNotification.hasCcRecipients = false;
      this.emailNotification.hasBccRecipients = false;
      this.emailNotification.hasRecipient = false;
      this.smsNotification.hasStatus = false;
      this.smsNotification.hasRecipients = false;

      this.emailNotification.ccRecipientsValue = "";
      this.emailNotification.bccRecipientsValue = "";
      this.emailNotification.recipientValue = "";
      this.smsNotification.statusValue = "";
      this.smsNotification.recipientsValue = "";

      // Set value
      this.id = currentNotification?.id ?? '';
      this.key = currentNotification?.key ?? '';
      this.type = currentNotification?.type ?? '';

      // For Email Settings
      this.emailNotification.senderName = currentNotification?.value?.email_notification?.sender_name ?? '';
      this.emailNotification.subject = currentNotification?.value?.email_notification?.subject ?? '';
      this.emailNotification.templateId = currentNotification?.value?.email_notification?.templateId ?? '';
      if (this.emailNotification.templateId) {
        this.selectedEmailTemplate = this.emailTemplates.find((emailTemplate) => this.emailNotification.templateId == emailTemplate.id)
      }
      this.emailNotification.templateName = currentNotification?.value?.email_notification?.templateName ?? '';
      this.emailNotification.senderEmailAddress = currentNotification?.value?.email_notification?.sender_email_address ?? '';
      if (currentNotification?.value?.email_notification?.recipient) {
        this.emailNotification.hasRecipient = true;
        this.emailNotification.recipientValue = currentNotification.value.email_notification.recipient;
      }
      if (currentNotification?.value?.email_notification?.cc_recipients) {
        this.emailNotification.hasCcRecipients = true;
        this.emailNotification.ccRecipientsValue = currentNotification.value.email_notification.cc_recipients.join(' , ');
      }
      if (currentNotification?.value?.email_notification?.bcc_recipients) {
        this.emailNotification.hasBccRecipients = true;
        this.emailNotification.bccRecipientsValue = currentNotification.value.email_notification.bcc_recipients.join(' , ');
      }

      // For SMS Settings
      this.smsNotification.senderName = currentNotification?.value?.sms_notification?.sender_name ?? '';
      this.smsNotification.templateId = currentNotification?.value?.sms_notification?.templateId ?? '';
      this.selectedSmsTemplate = {};
      if (this.smsNotification.templateId) {
        let smsTemplate = this.smsTemplates.find((template) => this.smsNotification.templateId == template.id);
        if (Object.keys(smsTemplate).length !== 0) {
          this.selectedSmsTemplate = this.smsTemplates.find((smsTemplate) => this.smsNotification.templateId == smsTemplate.id);
        } else {
          this.selectedSmsTemplate = {};
        }
      }
      this.smsNotification.templateName = currentNotification?.value?.sms_notification?.templateName ?? '';
      if (currentNotification?.value?.sms_notification?.recipients) {
        this.smsNotification.hasRecipients = true;
        this.smsNotification.recipientsValue = currentNotification.value.sms_notification.recipients.join(' , ');
      }
      if (currentNotification?.value?.sms_notification?.status === 0 || currentNotification?.value?.sms_notification?.status === 1) {
        this.smsNotification.hasStatus = true;
        this.smsNotification.statusValue = currentNotification.value.sms_notification.status == 0 ? false : 1;
      }
    },

    selectedEmailTemplate(currentSelectedTemplate) {
      this.emailNotification.templateName = currentSelectedTemplate.name;
    },
    selectedSmsTemplate(currentSelectedTemplate) {
      this.smsNotification.templateName = currentSelectedTemplate.name;
    }

  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    validationChecker() {
      //for email
      if (!this.emailNotification.senderName) {
        this.showToastMessage({
          type: 'error',
          message: `Email Sender Name can't be empty`
        });
        return;
      }
      if (!this.emailNotification.senderEmailAddress) {
        this.showToastMessage({
          type: 'error',
          message: `Sender Email Address can't be empty`
        });
        return;
      }
      if (!this.emailNotification.subject) {
        this.showToastMessage({
          type: 'error',
          message: `Email subject  can't be empty`
        });
        return;
      }
      if (!this.emailNotification.templateName) {
        this.showToastMessage({
          type: 'error',
          message: `Email template Name  can't be empty`
        });
        return;
      }
      if (!this.selectedEmailTemplate?.id) {
        this.showToastMessage({
          type: 'error',
          message: `Please select a Email Template `
        });
        return;
      }
      if ((this.emailNotification.hasRecipient)) {
        if (!this.emailNotification.recipientValue) {
          this.showToastMessage({
            type: 'error',
            message: `Recipient value can not be empty`
          });
          return;
        }
      }

      //for sms
      if (this.selectedSmsTemplate?.id) {
        if (!this.smsNotification.senderName) {
          this.showToastMessage({
            type: 'error',
            message: `Sms Sender Name can't be empty`
          });
          return;
        }
        if (!this.smsNotification.templateName) {
          this.showToastMessage({
            type: 'error',
            message: `Sms Template Name can't be empty`
          });
          return;
        }
        if (!this.selectedSmsTemplate?.id) {
          this.showToastMessage({
            type: 'error',
            message: `Please select a Sms Template`
          });
          return;
        }

      }

      if ((this.emailNotification.senderName + '' && this.emailNotification.senderEmailAddress + '' && this.emailNotification.subject + '' && this.selectedEmailTemplate?.id && this.emailNotification.templateName || ((this.selectedSmsTemplate?.id) && this.smsNotification.senderName + '' && this.selectedSmsTemplate?.id && this.smsNotification.templateName))) {
        return true;
      }
      return false;

    },
    async updateSetting() {

      if (this.validationChecker()) {
        const data = {
          type: this.type,
          key: this.key,
          value: {
            email_notification: {
              sender_name: this.emailNotification.senderName,
              sender_email_address: this.emailNotification.senderEmailAddress,
              subject: this.emailNotification.subject,
              templateId: this.selectedEmailTemplate.id,
              templateName: this.emailNotification.templateName
            },
            sms_notification: {
              sender_name: this.smsNotification.senderName,
              templateId: this.selectedSmsTemplate.id,
              templateName: this.smsNotification.templateName
            }
          },

        }
        if (this.emailNotification.hasRecipient) {
          data.value.email_notification.recipient = this.emailNotification.recipientValue.trim();
        }
        if (this.emailNotification.hasCcRecipients) {
          data.value.email_notification.cc_recipients = this.emailNotification.ccRecipientsValue.length > 0 ? this.emailNotification.ccRecipientsValue.split(',').map(item => item.trim()) : [];
        }
        if (this.emailNotification.hasBccRecipients) {
          data.value.email_notification.bcc_recipients = this.emailNotification.bccRecipientsValue.length > 0 ? this.emailNotification.bccRecipientsValue.split(',').map(item => item.trim()) : [];
        }
        if (this.smsNotification.hasRecipients) {
          data.value.sms_notification.recipients = this.smsNotification.recipientsValue.length > 0 ? this.smsNotification.recipientsValue.split(',').map(item => item.trim()) : [];
        }
        if (this.smsNotification.hasStatus) {
          data.value.sms_notification.status = this.smsNotification.statusValue === true ? 1 : 0;
        }
        const dataObj = {
          id: this.id,
          data: data
        }


        this.loader(true);
        const response = await this.putSetting(dataObj);
        this.loader(false);
        if (response.status === 200) {
          this.showToastMessage({
            type: 'success',
            message: `  Updated Successfully  `
          });
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }

      }

    }
  }
}
</script>

<style>
.multiselect-container .multiselect__content-wrapper {
  z-index: 99999 !important;
  height: auto !important;
}

.view-example-btn {
  background-color: #659aff24;
  padding: 0px 8px;
  font-size: 14px;
  color: #235ecf;
}
</style>